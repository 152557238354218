@charset "UTF-8";
/**
*   style.scss
*   @description: Constructor de la hoja de estilos
*   @variables: dev: boolean: Activa las opciones de desarrollo
*/
/*  
*   1.- Core Styles: trejocode.css
*   2.- Componentes: Todos los elementos posibles
*/
/*      Core       */
/*  INFORMATION
   =============================
    
	- File:           trejocode.css
	- Versión:        1.2
	- Licence:        Mozilla Public Licence. 
	- Author:         TrejoCode
    - Update:         11/06/2019
    - Client:         iGate
*/
/* RESET
   =============================
   - These properties able the cross compatibility for browsers and removes default properties from browsers.
*/
* {
  padding: 0;
  margin: 0; }

html {
  font-size: 100%; }

*, *:before, *:after {
  box-sizing: border-box; }

body {
  overflow-x: hidden;
  background-color: #F5F4F4; }

input, textarea, select, option, button {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none; }

table {
  border-spacing: 0;
  overflow-x: auto; }

table th {
  white-space: nowrap; }

input:focus, select:focus {
  outline: none;
  transition: 400ms ease-in-out; }

ul, ol {
  list-style: none; }

a {
  color: inherit;
  word-break: keep-all;
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

/*div, header, footer, section, article, aside, nav, form, table {
    width: 100%;
    transition: all 450ms;
}*/
/* CLASES
   =============================
	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = TABLES
    5. = IMAGES
    6. = DECORATORS & COLORS
*/
/*    FLEX LAYOUT
    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  width: 100%;
  display: flex; }

.row-reverse {
  flex-direction: row-reverse;
  display: flex; }

.container {
  width: 90%;
  display: flex; }

.responsive-img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.row {
  display: flex;
  flex-direction: row; }

.row-responsive {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.wrap {
  flex-wrap: wrap;
  display: flex; }

/*    ALIGNMENT    */
.justify-center {
  width: 100%;
  display: flex;
  justify-content: center; }

.justify-start {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.justify-end {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.justify-between {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.justify-around {
  width: 100%;
  display: flex;
  justify-content: space-around; }

.align-center {
  width: 100%;
  display: flex;
  align-items: center; }

.align-start {
  width: 100%;
  display: flex;
  align-items: flex-start; }

.align-end {
  width: 100%;
  display: flex;
  align-items: flex-end; }

.auto {
  width: auto;
  display: flex; }

.medium {
  width: 50%; }

.full {
  width: 100%; }

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-right {
  text-align: right; }

/*    TABLES    */
.table-responsive {
  overflow-x: auto; }

.table-responsive table {
  width: 100%; }

.table-auto {
  table-layout: auto; }

.table-equal {
  table-layout: fixed; }

.parrafo {
  width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*    IMAGES    */
.cover-img {
  object-fit: cover; }

.contain-img {
  object-fit: contain; }

/*    DECORATORS    */
.hidden {
  display: none !important; }

.cursor-pointer {
  cursor: pointer; }

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px; } }

/*    NOTEBOOKS, IPADS DEVICES    */
/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  .row-responsive {
    flex-direction: column; }
  .white-space-64 {
    height: 32px; } }

/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  .container {
    width: 92%; } }

/**
*   colors.scss
*   @description: Variables de colores
*/
/*		Componentes		*/
.dinamic-modal-small {
  width: 50%; }

.dinamic-modal-normal {
  width: 55%; }

.dinamic-modal-big {
  width: 57%; }

.dinamic-modal-huge {
  width: 90%; }

.dinamic-modal-container {
  top: 50%;
  left: 50%;
  outline: none;
  max-height: 100%;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  transition: max-height 1.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 2;
  /*.title {
		text-align: left;
	}*/ }
  .dinamic-modal-container .image-column {
    width: 40%;
    height: 100%; }
  .dinamic-modal-container .modal-container {
    width: 95%; }
  .dinamic-modal-container .dinamic-header {
    padding: 8px 0px 8px 0px;
    border-radius: 4px 4px 0 0;
    background-color: #4e8540;
    width: 100%;
    height: 60px;
    position: relative; }
    .dinamic-modal-container .dinamic-header .title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: capitalize; }
    .dinamic-modal-container .dinamic-header .btn-modal-close {
      position: absolute;
      right: 0.3%;
      top: 6%;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: transparent;
      color: #FFFFFF;
      font-size: 1rem;
      transition: background-color 400ms ease-in-out;
      cursor: pointer; }
      .dinamic-modal-container .dinamic-header .btn-modal-close:hover {
        color: white; }
  .dinamic-modal-container .dinamic-body {
    height: auto;
    max-height: 750px;
    overflow-y: scroll; }
  .dinamic-modal-container .label-normal {
    width: 35%; }
    .dinamic-modal-container .label-normal p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .label-medium {
    width: 41%; }
    .dinamic-modal-container .label-medium p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .label-midle {
    width: 29%;
    padding-left: 24px;
    margin-right: 10px; }
    .dinamic-modal-container .label-midle p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .column-small {
    width: 30%;
    padding-left: 16px; }
  .dinamic-modal-container .dinamic-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px; }
    .dinamic-modal-container .dinamic-footer .btn-action-success {
      width: 30%;
      padding: 9px 12px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      transition: all 300ms ease-in-out;
      border-radius: 4px;
      background-color: #1e85f4af;
      cursor: pointer;
      background-color: #1B66ED; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        background-color: #1078F2; }
      .dinamic-modal-container .dinamic-footer .btn-action-success i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        background-color: rgba(27, 100, 237, 0.897); }
    .dinamic-modal-container .dinamic-footer .btn-action-cancel {
      width: 30%;
      padding: 9px 12px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      transition: all 300ms ease-in-out;
      border-radius: 4px;
      background-color: #1e85f4af;
      cursor: pointer;
      background-color: #FF9607; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        background-color: #1078F2; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        background-color: rgba(255, 152, 7, 0.836); }

.overlay {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: height 1500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.4); }
  .overlay ::-webkit-scrollbar {
    width: 6px; }
  .overlay ::-webkit-scrollbar-thumb {
    background-color: #cccccc; }

.show-dinamic-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s; }

.hide-dinamic-modal {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; }

.btn-orange:disabled {
  background-color: #d25c0a;
  color: #eaeaea;
  cursor: not-allowed; }

.zoom_button {
  background-color: #2E8CFB;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white; }

/*      Páginas      */
.user-admin .input-text {
  width: 100%; }

.input-checkbox:after {
  content: '';
  position: relative;
  left: 40%;
  top: 20%;
  width: 30%;
  height: 60%; }
