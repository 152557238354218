* {
    padding: 0;
    margin: 0;
    transition: all 400ms ease
}

::selection {
    background: #E75f00;
    color: #fff
}

html {
    box-sizing: border-box
}

body {
    overflow-x: hidden;
    font-family: "Dosis", sans-serif;
    background-color: #fff
}

button,
input,
select,
textarea,
option {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none
}

table {
    border-spacing: 0;
    table-layout: fixed;
    overflow-x: auto
}

div,
header,
footer,
section,
article,
aside,
nav,
form,
table {
    width: 100%
}

ul,
ol {
    list-style: none
}

a {
    text-decoration: none;
    color: inherit
}

img {
    max-width: 100%;
    height: auto;
    object-fit: cover
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #272727
}

h1 {
    font-size: 1.8em;
    font-weight: 700
}

h2 {
    font-size: 1.6em;
    font-weight: 700
}

h3 {
    font-size: 1.4em;
    font-weight: 700
}

h4 {
    font-size: 1.2em;
    font-weight: 500
}

h5 {
    font-size: .93em;
    font-weight: 500
}

h6 {
    font-size: 1.4em;
    font-weight: 400
}

p {
    font-size: 1.2em;
    color: #979797;
    line-height: 24px
}

.flex {
    display: flex
}

.flex div,
.flex img,
.flex nav,
.flex form {
    display: flex
}

.container {
    width: 90%
}

.responsive-img {
    display: flex;
    justify-content: center;
    align-items: center
}

.row {
    flex-direction: row
}

.row-responsive {
    flex-direction: row
}

.column {
    flex-direction: column
}

.auto {
    width: auto
}

.grid {
    display: grid !important
}

.gutters {
    margin: 0 auto
}

.gutters>div {
    margin: 12px
}

.gutters>div:first-of-type {
    margin-left: 0;
    color: #272727
}

.gutters>div:last-of-type {
    margin-right: 0
}

.wrap {
    flex-wrap: wrap
}

.justify-center {
    justify-content: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-between {
    justify-content: space-between
}

.justify-arround {
    justify-content: space-around
}

.align-center {
    align-items: center
}

.align-start {
    align-items: flex-start
}

.align-end {
    align-items: flex-end
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-right {
    text-align: right
}

.white-space-8 {
    height: 8px
}

.white-space-16 {
    height: 16px
}

.white-space-24 {
    height: 24px
}

.white-space-32 {
    height: 32px
}

.white-space-48 {
    height: 48px
}

.white-space-64 {
    height: 64px
}

label {
    color: #979797
}

.label-error {
    color: #E74C3C
}

.input {
    color: #979797;
    font-size: .8em;
    font-family: "Dosis", sans-serif;
    font-weight: 500
}

.input-search {
    min-height: 45px;
    border: 2px solid #E9EBED;
    border-radius: 45px 0 0 45px;
    padding-left: 16px
}

.input:focus {
    border: 2px solid #8ebb36
}

.input-cta-email {
    height: 50px;
    align-self: flex-start;
    padding: 0 8px
}

.input-number {
    border: 2px solid #E9EBED;
    padding: 8px 4px
}

.input-text {
    color: #272727;
    border: 2px solid #E9EBED;
    border-radius: 4px;
    font-weight: 500;
    padding: 8px
}

.textarea {
    color: #272727;
    border: 2px solid #E9EBED;
    border-radius: 4px;
    font-weight: 500;
    padding: 8px;
    line-height: 18px
}

.input-select {
    border-radius: 4px;
    border: 2px solid #E9EBED;
    padding: 8px;
    color: #272727
}

.input-checkbox {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 16px;
    width: 16px;
    border: 1px solid white
}

.input-checkbox:checked {
    background: #2aa1c0
}

.input-checkbox:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.input-checkbox:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none
}

.input-checkbox:after {
    content: '';
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none
}

.input-checkbox:checked:after {
    display: block
}

.input-checkbox:disabled:after {
    border-color: #7b7b7b
}

.hidden {
    display: none !important
}

.btn {
    width: auto;
    height: auto;
    outline: none;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: .93em;
    font-family: "Dosis", sans-serif;
    font-weight: 700;
    padding: 16px 24px
}

.btn-small {
    padding: 8px 16px
}

.btn:hover>i {
    margin-left: 4px
}

.btn:active {
    transform: scale(0.96)
}

.btn-search {
    min-width: 20%;
    color: #9c9c9c;
    border: 2px solid #E9EBED;
    border-radius: 0 45px 45px 0px;
    border-left: 1px solid #E9EBED;
    transition: all 0.2s
}

.btn-white {
    background-color: #fff;
    color: #E75f00
}

.btn-orange {
    background-color: #E75f00;
    color: #fff
}

.btn-orange:hover {
    /*background-color: #fff;
    color: #E75f00;*/
    background-color: #d25c0a;
}

.btn-disabled {
    /*background-color: darkgray;
    color: #E75f00*/
    background-color: #d25c0a;
    cursor: not-allowed;
}

.btn-red {
    background-color: #E74C3C
}

.btn-red:hover {
    background-color: #C0392B
}

.link {
    cursor: pointer
}

.link:hover {
    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.9) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
            mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.9) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    -webkit-mask-size: 200%;
            mask-size: 200%;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%
    }
    to {
        -webkit-mask-position: -50%
    }
}

@keyframes shine {
    from {
        -webkit-mask-position: 150%
    }
    to {
        -webkit-mask-position: -50%
    }
}

.table-responsive {
    overflow-x: auto
}

.bordered {
    border: 2px solid #E9EBED
}

.color-white {
    color: #fff
}

.color-orange {
    color: #E75f00
}

.color-lightGreen {
    color: #8ebb36
}

.color-lightGray {
    color: #E9EBED
}

.color-darkGray {
    color: #979797
}

.color-black {
    color: #272727
}

.weight-medium {
    font-weight: 500
}

.weight-bold {
    font-weight: 700
}

.uppercase {
    text-transform: uppercase
}

.underline {
    text-decoration: underline
}

.text-mini {
    font-size: .8em
}

@media screen and (min-width: 1440px) {
    .container {
        max-width: 1440px
    }
}

@media screen and (max-width: 992px) {
    .break-row {
        flex-direction: column
    }
}

@media screen and (max-width: 768px) {
    .row-responsive {
        flex-direction: column
    }
    .white-space-64 {
        height: 32px
    }
    .gutters>div {
        margin: 0
    }
}

@media screen and (max-width: 576px) {
    .input-search {
        min-height: 38px;
        margin-left: -8px;
        padding-left: 8px
    }
}

header {
    background-image: linear-gradient(to right, #4e8540 5%, #8ebb36 5%, #8ebb36 90%)
}

header .logo img {
    max-height: 100px;
    margin-left: -8px
}

header .nav-link {
    margin-right: 8px
}

header .nav-link a {
    background-color: #fff;
    padding: 8px 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #8ebb36
}

header .nav-link a i {
    color: #E75f00;
    font-size: .9em
}

header .nav-link:hover {
    background-color: #4e8540;
    color: #fff
}

header .nav-link:hover>i {
    color: #fff
}

header #user i {
    -webkit-animation: saludar 1.5s ease;
            animation: saludar 1.5s ease;
    -webkit-animation-iteration-count: 3;
            animation-iteration-count: 3
}

@-webkit-keyframes saludar {
    0% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(45deg)
    }
    50% {
        transform: rotate(0deg)
    }
    75% {
        transform: rotate(-45deg)
    }
    100% {
        transform: rotate(0deg)
    }
}

@keyframes saludar {
    0% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(45deg)
    }
    50% {
        transform: rotate(0deg)
    }
    75% {
        transform: rotate(-45deg)
    }
    100% {
        transform: rotate(0deg)
    }
}

@media screen and (max-width: 768px) {
    header {
        background-image: linear-gradient(to right, #4e8540 0%, #8ebb36 0%, #8ebb36 100%)
    }
    header .logo {
        width: 100%;
        justify-content: center;
        align-items: center
    }
    header nav {
        margin: 8px 0;
        justify-content: center !important
    }
    header nav .nav-link a {
        text-align: center
    }
}

footer .footer-top {
    padding: 16px 0;
    background-color: #8ebb36
}

footer .logo img {
    height: 80px
}

footer .footer-nav {
    justify-content: flex-end
}

footer .footer-nav .item {
    font-size: 1.2em;
    font-weight: 700;
    color: #fff
}

footer .footer-bottom {
    padding: 16px 0;
    background-color: #4e8540
}

footer .footer-bottom a {
    width: 100%;
    text-align: right
}

@media screen and (max-width: 992px) {
    footer .logo {
        width: 40%
    }
}

@media screen and (max-width: 768px) {
    footer .logo {
        width: 100%;
        justify-content: center
    }
    footer .footer-nav {
        justify-content: space-between;
        flex-wrap: wrap
    }
}

.table-price th,
.table-sessions th,
.table-credits th {
    background: #4e8540;
    padding: 8px 0;
    color: #fff
}

.table-price tr:hover,
.table-sessions tr:hover,
.table-credits tr:hover {
    background-color: #E9EBED;
    cursor: pointer
}

.table-price td,
.table-sessions td,
.table-credits td {
    text-align: center;
    padding: 8px 0;
    border: 1px solid #efefef
}

.table-auto {
    table-layout: auto
}

@media screen and (max-width: 576px) {
    table {
        table-layout: auto
    }
    .table-price td,
    .table-sessions td,
    .table-credits td {
        padding: 8px 24px
    }
    #table-admin-sessions th {
        white-space: nowrap;
        padding: 8px 16px
    }
}

.user-admin .swipe-to-left {
    display: none
}

.user-admin .user-aside {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    min-width: 20%;
    max-width: 20%
}

.user-admin .user-aside .aside-head {
    padding: 16px;
    background-color: #4e8540
}

.user-admin .user-aside .aside-item {
    font-weight: 600;
    border-bottom: 1px solid #E9EBED;
    background-color: #f9f9f9
}

.user-admin .user-aside .aside-item a {
    padding: 16px;
    width: 100%
}

.user-admin .user-aside .aside-item a:hover {
    color: #E75f00
}

.user-admin .user-aside .aside-item a i {
    visibility: hidden;
    opacity: 0
}

.user-admin .user-aside .aside-item a:hover i {
    visibility: visible;
    opacity: 1
}

.user-admin .user-aside .credit-aside,
.user-admin .user-aside .next-aside {
    margin: 16px 0
}

.user-admin .user-aside .credit-aside .credit-head,
.user-admin .user-aside .credit-aside .next-head,
.user-admin .user-aside .next-aside .credit-head,
.user-admin .user-aside .next-aside .next-head {
    padding: 16px;
    background-color: #4e8540
}

.user-admin .user-aside .credit-aside p,
.user-admin .user-aside .next-aside p {
    padding: 16px 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #E9EBED
}

.user-admin .user-area {
    align-self: flex-start;
    border: 1px dotted #dcdcdc;
    margin: 0 16px;
    padding: 16px
}

.user-admin .user-area .help-page .left {
    margin-right: 32px
}

.user-admin .user-area .help-page .left img {
    max-width: 300px;
    object-fit: contain
}

.user-admin .user-area .help-page .suggest .btn-container {
    justify-content: flex-end
}

.user-admin .user-area .profile-page .input-group label {
    margin-right: 8px
}

.user-admin .user-area .profile-page .input-group .input,
.user-admin .user-area .profile-page .input-group select {
    width: 100%
}

.user-admin .user-area .profile-page .input-group textarea {
    max-width: 100%;
    margin-left: 16px;
    resize: none
}

.user-admin .user-area .profile-page .input-group input[type="checkbox"] {
    min-width: auto
}

.user-admin .user-area .profile-page .right img {
    min-width: 50%
}

.user-admin .user-area .profile-page .right .btn-container {
    justify-content: center
}

.user-admin .user-area .profile-page #profile-img-div {
    margin-left: 16px
}

.user-admin .user-area .schedule-page .search-container {
    justify-content: space-between;
    align-items: center
}

.user-admin .user-area .schedule-page .search-container input {
    align-self: stretch;
    min-width: 80%
}

.user-admin .user-area .schedule-page .filter-teachers {
    margin: 32px 0
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item {
    box-sizing: border-box;
    padding: 16px;
    background-color: #FBF7EF;
    border-top: 1px dotted #ffda9a;
    border-bottom: 1px dotted #ffda9a
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-picture img {
    object-fit: contain;
    max-height: 100px
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data {
    margin-left: 16px
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data .schedule p {
    font-size: .93em;
    margin: 8px
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data .schedule p:first-of-type {
    margin-left: 0
}

.user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-book {
    justify-content: flex-end;
    align-items: center;
    min-width: 20%
}

.user-admin .user-area .buy-page option {
    font-size: 1.2em
}

.user-admin .user-area .teachers-page .teachers .teachers-list-item {
    box-sizing: border-box;
    padding: 16px;
    background-color: #FBF7EF;
    border-top: 1px dotted #ffda9a;
    border-bottom: 1px dotted #ffda9a
}

.user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-picture img {
    object-fit: contain;
    max-width: 120px
}

.user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-data {
    margin-left: 16px
}

.user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-data .schedule p {
    font-size: .93em;
    margin: 8px
}

.user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-data .schedule p:first-of-type {
    margin-left: 0
}

@media screen and (max-width: 992px) {
    .user-admin .user-aside {
        position: static;
        position: initial;
        min-width: 100%;
        max-width: 100%
    }
    .user-admin .user-area {
        margin: 0;
        padding: 0;
        border: 0
    }
    .user-admin .user-area .help-page .left {
        margin-bottom: 16px;
        margin-right: 0
    }
    .user-admin .user-area .help-page .left img {
        max-width: auto;
        object-fit: contain
    }
    .user-admin .user-area .suggest .btn-container {
        justify-content: center
    }
    .user-admin .user-area .suggest .btn-container button {
        width: 100%
    }
    .user-admin .user-area .profile-page .right img {
        margin-top: 24px;
        min-width: 100%
    }
    .user-admin .user-area .schedule-page .search-container {
        justify-content: space-between;
        align-items: flex-start;
        margin: 8px 0
    }
    .user-admin .user-area .schedule-page .search-container input {
        align-self: stretch;
        min-width: 70%;
        margin: 8px 0
    }
    .user-admin .user-area .schedule-page .search-container #find-schedule {
        margin-top: 0
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-book {
        justify-content: flex-end;
        align-items: center;
        min-width: 40%
    }
}

@media screen and (max-width: 768px) {
    .swipe-to-left {
        display: flex !important
    }
    .user-admin .user-area .schedule-page .search-container {
        justify-content: flex-start
    }
    .user-admin .user-area .schedule-page .search-container .input-group {
        min-width: 100%
    }
    .user-admin .user-area .schedule-page .filter-container {
        margin-bottom: 8px
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item {
        margin: 8px 0
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-picture {
        justify-content: center;
        margin-bottom: 8px
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-picture img {
        border-radius: 50%
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data {
        width: 100%;
        margin-left: 0;
        text-align: center
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data .schedule {
        justify-content: center
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-data .schedule p {
        font-size: .93em;
        margin: 8px;
        text-align: center
    }
    .user-admin .user-area .schedule-page .avaible-sessions .avaible-sesions-item .teacher-book {
        margin: 8px 0;
        justify-content: center;
        min-width: 100%
    }
    .user-admin .user-area .schedule-page #duration,
    .user-admin .user-area .schedule-page #teacher-select {
        margin-top: 16px;
        width: 100%
    }
    .user-admin .user-area .schedule-page #find-schedule {
        margin-top: 8px;
        width: 100%
    }
    .user-admin .user-area .teachers-page .teachers .teachers-list-item {
        margin: 8px 0
    }
    .user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-picture {
        justify-content: center
    }
    .user-admin .user-area .teachers-page .teachers .teachers-list-item .teacher-data {
        margin-left: 0
    }
    .user-admin .user-area .right {
        margin-top: 16px
    }
    .user-admin .user-area .profile-page #profile-img-div {
        margin-left: 0
    }
}

.modal {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4)
}

@-webkit-keyframes animationAppearModal {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animationAppearModal {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

.modal .modal-content {
    width: 50%;
    margin: auto;
    position: relative;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animationAppearModal;
            animation-name: animationAppearModal;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms
}

.modal .modal-content .modal-header {
    padding: 16px 0;
    background-color: #4e8540;
    position: relative;
}

.modal-header .close-x {
    position: absolute;
    top: 0;
    cursor: pointer;
    padding-right: 10px;
}

.modal .modal-content .confirmation-data .teacher-picture {
    justify-content: center
}

.modal .modal-content .confirmation-data .teacher-picture img {
    border-radius: 50%;
    max-height: 100px
}

.modal .modal-content .confirmation-data p {
    margin: 0 16px
}

.modal .modal-content .confirmation-data .request textarea {
    width: 100%
}

.modal .modal-content .controls-group .accept {
    min-width: 60%
}

.modal .modal-content .controls-group .accept button {
    width: 100%
}

.modal .modal-content .controls-group .cancel button {
    width: 100%
}

#modal-calendar-row .modal-content {
    width: 65%
}

.close-modal {
    -webkit-animation-name: animationCloseModal;
            animation-name: animationCloseModal;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms
}

@-webkit-keyframes animationCloseModal {
    from {
        top: 0;
        opacity: 1
    }
    to {
        top: -300px;
        opacity: 0
    }
}

@keyframes animationCloseModal {
    from {
        top: 0;
        opacity: 1
    }
    to {
        top: -300px;
        opacity: 0
    }
}

@media screen and (max-width: 992px) {
    .modal .modal-content,
    #modal-calendar-row .modal-content {
        width: 60%
    }
}

@media screen and (max-width: 768px) {
    .modal .modal-content,
    #modal-calendar-row .modal-content {
        width: 90%
    }
    #modal-users #add-credits {
        margin: 16px 0
    }
}

.admin-area {
    align-self: flex-start;
    border: 1px dotted #dcdcdc;
    margin: 0 16px;
    padding: 16px
}

.admin-area .sessions-page {
    /*margin-right: 32px*/
}

.admin-area .sessions-page .input-group {
    padding-right: 12px;
}

.admin-area #active-calendar {
    width: 60%;
}

.admin-area #p-timezone {
    min-width: 250px
}

@media screen and (max-width: 992px) {
    .admin-area {
        margin: 0;
        padding: 0;
        border: 0
    }
    .admin-area .sessions-page {
        margin-top: 32px;
        margin-right: 0
    }
    .admin-area .sessions-page .admin-session-filter input {
        margin: 8px 0;
        width: 100%
    }
    .admin-area .sessions-page .admin-session-filter select {
        width: 100%;
        margin-left: 0;
        margin: 8px 0 16px 0
    }
    .admin-area .sessions-page .admin-session-filter .btn {
        width: 100%
    }
    .admin-area .sessions-page #calendar-inputs select {
        margin: 8px 0
    }
    .admin-area .sessions-page #calendar_edit_name {
        width: 100%
    }
    .admin-area .sessions-page #active-calendar,
    .admin-area .sessions-page #p-timezone {
        margin: 8px 0
    }
    .admin-area .sessions-page #p-timezone {
        min-width: 250px
    }
}

.pagination .page {
    transition: all 0.1s;
    border: 1px solid #ddd;
    padding: 4px 3px;
    margin: 0 4px;
    font-size: 14px;
    border-radius: 2px;
}

.pagination .page:hover {
    background-color: #E75f00;
    border: 2px solid #E75f00;
    color: #fff
}

.pagination .page-link {
    padding: 0 8px;
    font-size: 1.2em
}

.pagination .current-page {
    border: 3px solid #E75f00;
}

.introduction {
    background-attachment: fixed;
    border-bottom: 2px solid #f5f5f5;
    padding: 24px 0
}

.introduction .left {
    justify-content: center
}

.introduction .left img {
    object-fit: contain
}

.promotional-video {
    background-color: #f5f5f5
}

.promotional-video iframe {
    width: 100%;
    height: 600px
}

.forthis {
    background: #f5f5f5
}

.forthis .card {
    background-color: #fff;
    border: 2px solid #eeeeee;
    padding: 0 16px
}

.forthis .card img {
    height: 64px
}

.forthis .card .line {
    width: 48px;
    border: 1px solid #272727
}

.forthis .card p {
    font-weight: 500
}

.testimonial .photo img {
    height: 50px;
    margin-right: 16px
}

@media screen and (max-width: 992px) {
    .introduction .left {
        margin-right: 16px
    }
}

@media screen and (max-width: 768px) {
    .introduction .left {
        margin-right: 0
    }
    .promotional-video iframe {
        height: 300px
    }
    .forthis .card {
        padding: 0;
        margin: 16px 0
    }
    .forthis .card .icon,
    .forthis .card h4,
    .forthis .card p {
        padding: 0 16px
    }
    .forthis .card .line {
        margin: 0 16px
    }
}

@media screen and (max-width: 576px) {
    .promotional-video iframe {
        height: 200px
    }
}

.input-group label {
    min-width: 12%
}

.input-group input,
.input-group select {
    min-width: 50%;
    margin-left: 16px
}

.input-group input[type="checkbox"] {
    min-width: auto
}

@media screen and (max-width: 992px) {
    .input-group label {
        min-width: 25%
    }
    .input-group input,
    .input-group select {
        min-width: 60%
    }
}

#pricing-buttons .btn {
    margin: 8px 0;
    width: 100%
}

.admin-session-filter .input-group {
    padding-right: 12px;
}

.admin-session-filter .btn {
    padding: 9px 24px;
    border-radius: 2px;
}

.user-search .btn {
    padding: 9px 24px;
    border-radius: 2px;
}

.btn-user-search {
    padding-left: 12px;
}

#find-schedule {
    padding: 9px 26px;
}

.big-spinner i {
    font-size: 5em;
    color: #4e8540;
}

.medium-spinner i {
    font-size: 4em;
    color: #4e8540;
}

.small-spinner i {
    font-size: 3em;
    color: #4e8540;
}

.view .btn {
    padding: 9px 26px;
}

/*# sourceMappingURL=style.css.map */

@charset "UTF-8";
/**
*   style.scss
*   @description: Constructor de la hoja de estilos
*   @variables: dev: boolean: Activa las opciones de desarrollo
*/
/*  
*   1.- Core Styles: trejocode.css
*   2.- Componentes: Todos los elementos posibles
*/
/*      Core       */
/*  INFORMATION
   =============================
    
	- File:           trejocode.css
	- Versión:        1.2
	- Licence:        Mozilla Public Licence. 
	- Author:         TrejoCode
    - Update:         11/06/2019
    - Client:         iGate
*/
/* RESET
   =============================
   - These properties able the cross compatibility for browsers and removes default properties from browsers.
*/
* {
  padding: 0;
  margin: 0; }

html {
  font-size: 100%; }

*, *:before, *:after {
  box-sizing: border-box; }

body {
  overflow-x: hidden;
  background-color: #F5F4F4; }

input, textarea, select, option, button {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none; }

table {
  border-spacing: 0;
  overflow-x: auto; }

table th {
  white-space: nowrap; }

input:focus, select:focus {
  outline: none;
  transition: 400ms ease-in-out; }

ul, ol {
  list-style: none; }

a {
  color: inherit;
  word-break: keep-all;
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

/*div, header, footer, section, article, aside, nav, form, table {
    width: 100%;
    transition: all 450ms;
}*/
/* CLASES
   =============================
	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = TABLES
    5. = IMAGES
    6. = DECORATORS & COLORS
*/
/*    FLEX LAYOUT
    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  width: 100%;
  display: flex; }

.row-reverse {
  flex-direction: row-reverse;
  display: flex; }

.container {
  width: 90%;
  display: flex; }

.responsive-img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.row {
  display: flex;
  flex-direction: row; }

.row-responsive {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.wrap {
  flex-wrap: wrap;
  display: flex; }

/*    ALIGNMENT    */
.justify-center {
  width: 100%;
  display: flex;
  justify-content: center; }

.justify-start {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.justify-end {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.justify-between {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.justify-around {
  width: 100%;
  display: flex;
  justify-content: space-around; }

.align-center {
  width: 100%;
  display: flex;
  align-items: center; }

.align-start {
  width: 100%;
  display: flex;
  align-items: flex-start; }

.align-end {
  width: 100%;
  display: flex;
  align-items: flex-end; }

.auto {
  width: auto;
  display: flex; }

.medium {
  width: 50%; }

.full {
  width: 100%; }

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-right {
  text-align: right; }

/*    TABLES    */
.table-responsive {
  overflow-x: auto; }

.table-responsive table {
  width: 100%; }

.table-auto {
  table-layout: auto; }

.table-equal {
  table-layout: fixed; }

.parrafo {
  width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*    IMAGES    */
.cover-img {
  object-fit: cover; }

.contain-img {
  object-fit: contain; }

/*    DECORATORS    */
.hidden {
  display: none !important; }

.cursor-pointer {
  cursor: pointer; }

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px; } }

/*    NOTEBOOKS, IPADS DEVICES    */
/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  .row-responsive {
    flex-direction: column; }
  .white-space-64 {
    height: 32px; } }

/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  .container {
    width: 92%; } }

/**
*   colors.scss
*   @description: Variables de colores
*/
/*		Componentes		*/
.dinamic-modal-small {
  width: 50%; }

.dinamic-modal-normal {
  width: 55%; }

.dinamic-modal-big {
  width: 57%; }

.dinamic-modal-huge {
  width: 90%; }

.dinamic-modal-container {
  top: 50%;
  left: 50%;
  outline: none;
  max-height: 100%;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  transition: max-height 1.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 2;
  /*.title {
		text-align: left;
	}*/ }
  .dinamic-modal-container .image-column {
    width: 40%;
    height: 100%; }
  .dinamic-modal-container .modal-container {
    width: 95%; }
  .dinamic-modal-container .dinamic-header {
    padding: 8px 0px 8px 0px;
    border-radius: 4px 4px 0 0;
    background-color: #4e8540;
    width: 100%;
    height: 60px;
    position: relative; }
    .dinamic-modal-container .dinamic-header .title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: capitalize; }
    .dinamic-modal-container .dinamic-header .btn-modal-close {
      position: absolute;
      right: 0.3%;
      top: 6%;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: transparent;
      color: #FFFFFF;
      font-size: 1rem;
      transition: background-color 400ms ease-in-out;
      cursor: pointer; }
      .dinamic-modal-container .dinamic-header .btn-modal-close:hover {
        color: white; }
  .dinamic-modal-container .dinamic-body {
    height: auto;
    max-height: 750px;
    overflow-y: scroll; }
  .dinamic-modal-container .label-normal {
    width: 35%; }
    .dinamic-modal-container .label-normal p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .label-medium {
    width: 41%; }
    .dinamic-modal-container .label-medium p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .label-midle {
    width: 29%;
    padding-left: 24px;
    margin-right: 10px; }
    .dinamic-modal-container .label-midle p {
      font-size: 1.2rem;
      color: white; }
  .dinamic-modal-container .column-small {
    width: 30%;
    padding-left: 16px; }
  .dinamic-modal-container .dinamic-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px; }
    .dinamic-modal-container .dinamic-footer .btn-action-success {
      width: 30%;
      padding: 9px 12px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      transition: all 300ms ease-in-out;
      border-radius: 4px;
      background-color: #1e85f4af;
      cursor: pointer;
      background-color: #1B66ED; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        background-color: #1078F2; }
      .dinamic-modal-container .dinamic-footer .btn-action-success i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        background-color: rgba(27, 100, 237, 0.897); }
    .dinamic-modal-container .dinamic-footer .btn-action-cancel {
      width: 30%;
      padding: 9px 12px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      transition: all 300ms ease-in-out;
      border-radius: 4px;
      background-color: #1e85f4af;
      cursor: pointer;
      background-color: #FF9607; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        background-color: #1078F2; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        background-color: rgba(255, 152, 7, 0.836); }

.overlay {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: height 1500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.4); }
  .overlay ::-webkit-scrollbar {
    width: 6px; }
  .overlay ::-webkit-scrollbar-thumb {
    background-color: #cccccc; }

.show-dinamic-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s; }

.hide-dinamic-modal {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; }

.btn-orange:disabled {
  background-color: #d25c0a;
  color: #eaeaea;
  cursor: not-allowed; }

.zoom_button {
  background-color: #2E8CFB;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white; }

/*      Páginas      */
.user-admin .input-text {
  width: 100%; }

.input-checkbox:after {
  content: '';
  position: relative;
  left: 40%;
  top: 20%;
  width: 30%;
  height: 60%; }

