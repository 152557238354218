// COLORES PRINCIPALES DEL MODAL

$success: #1B66ED;
$successLight: #1B66ED;
$cancel: #FF9607;
$cancelLight: rgba(255, 152, 7, 0.836);

$overlay: rgba(0,0,0,0.4);
$header: $white;
$hoverBtnClose: #6c757d86;
$background: rgba(0,0,0,0.4);;

// ESTILOS GENERALES 
@mixin button-action {
	width: 30%;
	padding: 9px 12px;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 500;
	color: white;
	transition: all 300ms ease-in-out;
	border-radius: 4px;
	background-color: #1e85f4af;
	cursor: pointer;
	
	&:hover {
		background-color: #1078F2;
	  }
	i {
		font-size: 1rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		text-shadow: 0px 0px 6px rgba(255, 255, 255, .4);
		-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		transition: all 0.4s ease 0s;
	}
}
// TAMAÑO MODAL

.dinamic-modal-small {
	width: 50%;
}

.dinamic-modal-normal {
	width: 55%;
}

.dinamic-modal-big {
	width: 57%;
}

.dinamic-modal-huge {
	width: 90%;
}
// ESTILOS CUERPO MODAL

.dinamic-modal-container {
	top: 50%;
    left: 50%; 
    outline: none;
    max-height: 100%;
   	//overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    //padding: $paddingMedium;
	background-color: $white;
	transition: max-height 1.5s ease-in-out;
    transform: translate(-50%, -50%);
	z-index: 2;
	
	.image-column {
		width: 40%;
		height: 100%;
	}
	
	/*.title {
		text-align: left;
	}*/

	.modal-container {
		width: 95%;
	}

	.dinamic-header {
		padding: 8px 0px 8px 0px;
		border-radius: 4px 4px 0 0;
		background-color: #4e8540;;
		width: 100%;
		height: 60px;
        position: relative;
		.title {
			color: $white;
			font-weight: 700;
			font-size: 1.6rem;
			text-transform: capitalize;
		}

		.btn-modal-close {
			position: absolute;
			right: 0.3%;
			top: 6%;
			height: 35px;
			width: 35px;
			border-radius: 50%;
			background-color: transparent;
			color: $white;
			font-size: 1rem;
			transition: background-color 400ms ease-in-out;
			cursor: pointer;

			&:hover {
				color: white;
			}
		}

	}

	.dinamic-body {
		height: auto;
		max-height: 750px;
		overflow-y: scroll;
	}

	.label-normal {
		width: 35%;

		p {
			font-size: 1.2rem;
			color: white;
		}
	}

	.label-medium {
		width: 41%;

		p {
			font-size: 1.2rem;
			color: white;
		}
	}

	.label-midle {
		width: 29%;
		padding-left: 24px;
		margin-right: 10px;
		p {
			font-size: 1.2rem;
			color: white;
		}
	}


	.column-small {
		width: 30%;
		padding-left: 16px;
	}

	.dinamic-footer {
		flex-direction: column;
		justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 20px;

		.btn-action-success {
			@include button-action;
			background-color: $success;

			&:hover {
				background-color: rgba(27, 100, 237, 0.897);
			}
		}

		.btn-action-cancel {
			@include button-action;
			background-color: $cancel;

			&:hover {
				background-color: $cancelLight;
			}
		}
	}
}

.overlay {
	top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: height 1500ms ease-in-out;
    background-color: $overlay;
    ::-webkit-scrollbar { width: 6px; }
	::-webkit-scrollbar-thumb { background-color: #cccccc;}
}

.show-dinamic-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-dinamic-modal {
	opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}