.btn-orange:disabled{
	background-color: #d25c0a;
	color: #eaeaea;
	cursor: not-allowed;
}

.zoom_button {
	background-color: #2E8CFB;
	padding: 10px;
	border-radius: 5px;
	text-decoration: none;
	color: white;
}